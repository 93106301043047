@import "../../../assets/styles/_variables.scss";

.weekrangeinput {
    position: relative;
    display: flex;
    .weekrangeinput-picker {
        position: absolute;
        left: $space-16;
        top: $space-40;
        z-index: 10;
    }
}
