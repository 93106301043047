@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.aldi-button {
    font-weight: 500;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    height: $space-40;
    padding: 0 $space-16;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    @include roboto;
    @include type-14-regular;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.aldi-button:hover {
    color: $color-secondary-80;
}
.aldi-button:active {
    color: $color-secondary-50;
}
.aldi-button:disabled {
    cursor: not-allowed;
}
.aldi-button.primary {
    background-color: $color-blue;
    color: #fff;
    border-width: 1px;
    border-color: $color-blue;
}
.aldi-button.primary:hover {
    background-color: $color-primary-80;
    color: #fff;
    border-width: 1px;
    border-color: $color-primary-80;
}
.aldi-button.primary:active {
    background-color: $color-primary-medium;
    color: $color-secondary-130;
    border-width: 1px;
    border-color: $color-primary-medium;
}
.aldi-button.primary:disabled {
    background-color: rgba(31, 31, 31, 0.15);
    color: #1C1B1F;
    border-color: rgba(31, 31, 31, 0.15);
}

.aldi-button.secondary {
    background-color: transparent;
    border-width: 0;
    color: $color-blue;
    &:hover {
        color: $color-primary-80;
        background-color: transparent;
        border-width: 0;
    }
    &:disabled {
        color: rgba(31, 31, 31, 0.5);
        background-color: transparent;
    }
}


.aldi-button.tertiary {
    background-color: #fff;
    border-width: 1px;
    border-color: $color-blue;
    color: $color-blue;
}
.aldi-button.tertiary:hover {
    background-color: $color-blue;
    border-color: $color-blue;
    color: #fff;
}
.aldi-button.tertiary:active {
    background-color: $color-primary-medium;
    color: $color-secondary-130;
    border-color: $color-primary-medium;
}
.aldi-button.tertiary:disabled {
    background-color: rgba(0,0,0,0);
    border-color: $color-secondary-50;
    color: $color-secondary-50;
}

.aldi-button.danger {
    background-color: $color-danger;
    border-width: 1px;
    border-color: $color-danger;
    color: white;
}

.aldi-button.danger:hover {
    background-color: $color-danger;
    border-color: #c8392c;
    color: $color-secondary-10;
}

.aldi-button.danger:active {
    background-color: $color-danger;
    border-color: $color-danger;
    color: $color-secondary-10;
}

.aldi-button.danger:disabled {
    background-color: $color-secondary-50;
    border-color: #dfc1be;
    color: $color-secondary-10;
}

.aldi-button.ghost {
    background-color: transparent;
    border-width: 2px;
    border-color: #caebf7;
    color: $color-blue;
    &:hover {
        color: $color-primary-80;
        background-color: transparent;
        border-width: 2px;
        border-color: #caebf7;
    }
    &:disabled {
        color: rgba(31, 31, 31, 0.5);
        background-color: transparent;
        border-color: $color-secondary-20;
    }
}

.aldi-button.loading-indicator{
    position: relative;
    &:after{
        content: ' ';
        position: absolute;
        background:$color-primary-80 ;
        width: 20px;
        height: 3px;
        left:calc(100% - 20px);
        bottom: 1px;
        animation: loading 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    }
}
