@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_typography.mixin.scss";
@import "../../../../assets/styles/_table.mixin.scss";
@import "../../../../assets/styles/_page.mixin.scss";

.task-overview {
    .temperature {
        @include type-14-regular;
    }

    .status-icon {
        width: $space-16;
        height: $space-16;
        margin-right: $space-8;
    }

}
