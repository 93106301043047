@import "../../../assets/styles/aldi_colors";
@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.aldi-filter-menu {
    .filter-icon {
        width: $space-16;
    }

    .aldi-button {
        border-color:  rgba(0,0,0,0.23);
        color: $aldi-blue;

        &.selected {
            border-color: $aldi-blue;
        }
        
        &.active {
            background-color: $color-primary-medium;
            color: $color-secondary-130;
            border-color: $color-primary-medium;
            &.selected {
                border-color: $aldi-blue;
            }
        }
    }

}

.aldi-filter-popover {
    padding: $space-16;
    display: flex;
    gap: $space-12;
    flex-direction: column;
    align-items: stretch;
}

