@import "../../../../assets/styles/_typography.mixin.scss";
@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_effects.mixin.scss";

.location-overview {
    .table-row-clickable {
        cursor: pointer;
    }
    .table-cell-main-text {
        @include type-14-regular;
        line-height: 19px;
        margin: 0;
        margin-bottom: 16px;
    }
    .table-cell-main-text-with-subtext {
        @include type-14-regular;
        line-height: 19px;
        margin: 0;
    }
    .table-cell-subtext {
        @include type-12-regular;
        color: #979797;
        line-height: 16px;
        margin: 0;
    }
    .table-cell-subtext-button {
        @include type-12-regular;
        color: #1396CB;
        line-height: 16px;
        margin: 0;
        white-space: nowrap;
    }
}
