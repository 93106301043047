@import "./assets/styles/_variables.scss";
@import "./assets/styles/_typography.mixin.scss";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

html, body, #root, .frame {
    height: 100%;
}

html {
    background: $color-background-default;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;

    body {
        margin: 0;
        min-width: 900px;

        h1 {
            @include headline-1;
        }

        #root .frame {
            display: flex;

            .sidebar-wrapper {
                flex-shrink: 0;
                flex-grow: 0;
                width: 265px;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                border-right: 1px solid $color-border-sidebar;

                .sidebar {
                    width: auto;
                }
            }

            .page-content-wrapper {
                flex-grow: 1;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                .content {
                    display: flex;
                    align-items: stretch;
                    position: relative;
                    flex-grow: 1;

                    .outlet {
                        flex-grow: 1;
                        position:absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        overflow: auto;
                        padding: 80px 50px 0 50px;
                        .status-icon {
                            width: $space-16;
                            height: $space-16;
                            margin-right: $space-8;
                        }
                    }
                }
            }
        }

        .filter-menu {
            .filter {
                margin-left: 0;
                .aldi-kwmc-multi-select, .aldi-kwmc-select, .aldi-button {
                    width: 220px;
                }
            }
        }
    }
}
