@import "../../../../assets/styles/_variables.scss";

.aldi-kwmc-reset-button-wrapper {
}

.aldi-kwmc-reset-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $space-40;
    font-size: $space-16;
    width: $space-32;
    color: $color-secondary-60;
    box-sizing: border-box;
    border: 1px solid rgb(192, 192, 192);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left-width: 0;
    cursor: pointer;

    &:hover {
        color: $color-secondary-80;
    }
}