@import "../../../../assets/styles/_aldi_colors.scss";
@import "../../../../assets/styles/_typography.mixin.scss";
@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_effects.mixin.scss";

.timeframe-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.table {
    table-layout: fixed;
    .wrapper {
        justify-content: center;
    }
    .time-entry {
        font-weight: 600;
    }
}

.table-head-spacer {
    padding-bottom: $space-4;
}

.weekrange-row {
    display: flex;
}

.reset-week-button {
    padding: 0 !important;
    color: #79747E;
}

.icon-reset-week {
    font-size: 1rem;
    display: flex;
}

.set-weeks-button {
    margin-left: $space-16
}

.cleveron-timeframe-table {
    position: relative;

    .timeframe-week-wrapper {
        position: absolute;
        width: 87.5%;
        top: 0;
        right: 0;
        margin-top: 56px;
        padding-top: $space-4;
        height: 100%;
        margin-left: 10rem;
        // border: 2px solid black;
        display: flex;
    }

    .timeframe-day-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        min-width: $space-64;
        flex: 1;
        padding: 0;

        .day-before {
            cursor: pointer;
        }
    }

    .timeframes-wrapper-button {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: calc(100% - $space-16);
        flex: 1;
        padding: 0 $space-16;
        border: 0;
        overflow: visible;
        cursor: pointer;
    }

    .timeframes-wrapper {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: calc(100% - $space-16);
        flex: 1;
        padding: 0 $space-16;
    }

    .timeframe-cleveron-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1;
        padding: 0 $space-4;
    }

    .timeframe-cleveron {
        border-radius: 0.5rem;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        color: white;
        opacity: 0.7;
        transition: opacity 100ms ease-in;
    }

    .timeframes-wrapper:hover .timeframe-cleveron,
    .timeframes-wrapper-button:hover .timeframe-cleveron {
        opacity: 1;
    }

    .buffer {
        width: 100%;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: white;
        opacity: 0.7;
        margin-top: $space-4;
        background-color: $color-primary-50;
    }

    .buffer-text {
        margin: 0;
        @include type-10-regular;
    }

    .add-timeframe-button-wrapper {
        width: calc(100% - $space-16);
        height: calc(100% - $space-16 - $space-4);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        padding: 0 $space-16;
    }

    .add-timeframe-button {
        width: 100%;
        max-width: 100%;
        border-radius: 0.5rem;
        border: 1px dashed $color-primary-50;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $color-primary-50;
        background-color: $color-primary-light;
        opacity: 0.5;
        padding: 0;
        margin-bottom: calc($space-32 + $space-8);
        transition: background-color 100ms ease-in-out;
    }

    .add-timeframe-button:hover {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        background-color: $aldi-blue;
    }

    .add-timeframe-button-text-title {
        margin: 0 auto;
        font-weight: 700;
        @include type-12-regular;
    }

    .add-timeframe-button-text {
        margin: 0 auto;
        font-weight: 400;
        @include type-12-regular;
    }

    .no-access-icon {
        font-size: 32px;
        margin-top: $space-12;
    }

    .timeframe-name {
        margin: 0;
        font-weight: 600;
        text-transform: uppercase;
        @include type-14-regular;
    }

    .timeframe-time {
        margin: 0;
        font-weight: 600;
        @include type-12-regular;
    }

    .delete-timeframe-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 150%;
    }

    .delete-timeframe-button {
        font-size: 24px;
        width: $space-40;
        height: $space-40;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }


    .button-wrapper {
        width: calc(100% - $space-16);
        height: calc(100% - $space-16 - $space-4);
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        padding: 0 $space-16;

        .disabled-button {
            width: 100%;
            max-width: 100%;
            height: calc(100% - $space-16);
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: white;
            background-color: $color-secondary-70;
            opacity: 0.5;
            padding: 0;

            .disabled-button-text {
                width: calc(100% - 4px);
                display: block;
                white-space: initial;
                font-weight: 600;
                margin: 0 2px;
                @include type-12-regular;
            }
        }
    }
}

.container-header-week-range-picker {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: $space-32;
    position: relative;

    .week-range-picker {
        position: absolute;
        z-index: 1000;
        @include drop-shadow-50;
        top: calc(100% + $space-2);
        right: 0;
    }
}

.header-row {
    width: 100%;
    display: flex;

    .placeholder {
        display: block;
        flex-grow: 1;
    }
}


.legend-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
}

.legend-cleveron {
    display: flex;
    align-items: center;
}

.legend-cleveron-color-pad {
    width: $space-24;
    height: $space-24;
    border-radius: $space-4;
    margin-right: $space-16;
}

.legend-cleveron-label {
    font-weight: 400;
    @include type-14-regular;
    margin-right: $space-48;
    float: inline-end;
}
