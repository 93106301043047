@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.aldi-week-range-picker {
    width: 540px;
    height: 280px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    .kw-calendar-month.kw-weekpicker-left {
        border-radius: 0 0 0 4px;
    }
    .kw-calendar-month.kw-weekpicker-right {
        border-radius: 0 0 4px 0;
    }
    .kw-separator {
        width: $space-8;
        margin: $space-2 -$space-2;
        background-color: #eeeeee;
    }

    .kw-calendar-month {
        font-weight: 400;
        font-size: 16px;
        background-color: #ffffff;
        border-radius: 4px;

        .kw-month-header {
            display: flex;
            background-color: #ffffff;
            border: 0;
            .kw-month-show-previous, .kw-month-show-next {
                cursor: pointer;
                margin: $space-8 $space-16;
                &.hidden {
                    visibility: hidden;
                }
            }
            .kw-month-title {
                flex: auto;
                background-color: #ffffff;
                font-style: normal;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.4px;
                color: rgba(0, 0, 0, 0.87);
                border: none;
                height: 44px;
            }
        }


        .kw-month-view {
            width: auto;
            margin: 0px 18px 8px 18px;
            background-color: #ffffff;

            .kw-week {
                margin: 2px;
                .kw-date-item {
                    width: 28px;
                    height: 28px;
                    color: rgba(0, 0, 0, 0.87);
                }
                .kw-date-item-not-current {
                    color: rgba(0,0,0,0);
                    background-color: rgba(0,0,0,0);
                }
                .kw-date-item-calendar-week {
                    color: #979797;
                    margin-right: 2px;
                }
                &:hover{
                    background-color: #f2f6fd;
                    border-radius: 24px;
                }
            }
            .kw-date-item {
                &:nth-child(2) {
                    border-radius: 16px 0px 0px 16px;
                }
                &:nth-child(8) {
                    border-radius: 0px 16px 16px 0px;
                }
            }

            .kw-week.kw-week-headers {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.5px;
                color: rgba(0, 0, 0, 0.6);
                .kw-date-item-calendar-week {
                    cursor: auto;
                    color: #979797;
                    margin-right: 2px;
                }
                margin: 2px;
                .kw-date-item {
                    cursor: auto;
                    width: 28px;
                    height: 28px;
                }
                &:hover{
                    background-color: rgba(0,0,0,0)
                }
            }

            .kw-week.kw-week-days {
                &:hover{
                    background-color: rgba(0,0,0,0);
                    border-radius: 24px;
                }
            }

            .kw-week-selection-start, .kw-week-selection-between, .kw-week-selection-end {
                background-color: rgba(0,0,0,0);
                .kw-date-item {
                    background-color: #e2f6fd;
                    &.kw-date-item-not-current {
                        color: #e2f6fd;
                    }
                }
            }
            .kw-week-selection-start, .kw-week-selection-between, .kw-week-selection-end {
                .kw-date-item-calendar-week {
                    color: white;
                    background-color: #3FC0F0;
                    border-radius: 24px;
                }
            }
            .kw-date-item-calendar-week {
                background-color: rgba(0,0,0,0);
            }
        }
    }
}
