@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.form-message {
    background-color: $color-secondary-20;
    margin: $space-32 0;
    padding: $space-12;
    color: initial;
    border-left: $space-4 solid $color-danger;

    .form-message-title {
        @include type-12-regular;
        font-weight: 700;
    }
    .form-message-message {
        @include type-12-regular;
    }
}