@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";

header.main {
    display: flex;
    background: $color-blue;
    color: #fff;
    border-bottom: 1px solid $color-border;
    height: 60px;

    @include roboto;
    @include type-20-regular;
    @include type-bold;

    div.header-secondary {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .back {
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .user {
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            .menu-content {
                margin: 20px;
            }

            .user-name {
                display: flex;
                align-items: end;
                cursor: pointer;

                .user-icon {
                    margin-right: 0;
                }

            }
        }
    }
}