@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";



.slider-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.period-modal {
    .aldi-modal-background {
        .modal-wrapper {
            width: fit-content;
        }
    }
}
