@import "../../../assets/styles/variables";
@import "../../../assets/styles/effects.mixin";
@import "../../../assets/styles/typography.mixin";

$badge-size: 8rem;
$border-width: 1px;

.badge {

    height: 32px;
    width: 47px;

    &.danger{
        background: rgba(237, 107, 91, 0.05);
        color: #ED6B5B;
    }

    &.success{
        background: rgba(146, 208, 80, 0.25);
        color: #92D050;
    }

    &.warning{
        background: rgba(250, 180, 0, 0.15);
        color: #FAB400;
    }

    &.blue{
        background: rgba(34, 44, 120, 0.25);
        color: #222C78;
    }

    &.grey{
        background: rgba(151, 151, 151, 0.25);
        color: #979797;
    }

    &.orange{
        background: rgba(238, 114, 2, 0.25);
        color: #EE7202;
    }

    &.purple{
        background: rgba(145, 20, 64, 0.25);
        color: #911440;
    }

}