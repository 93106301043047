@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";

.subtitle {
    margin-bottom: 24px;
}

.message {
    @include type-12-regular;
    margin: 0;
    color: #49454F;
    font-weight: 400;
    line-height: 20px;
}

.radio-value-label {
    color: $color-primary-100;
    font-size: 1rem;
    font-weight: 400;
    margin-right: $space-8;
    line-height: normal;
}
