@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_effects.mixin.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.replenishment {
    display: flex;
    flex-direction: column;
    @include type-16-regular;

    .config {
        flex-grow: 1;

        .parameter {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $space-16;
            height: $space-96;
            .name {
                flex-grow: 1;
                margin-bottom: 1.7rem;
            }
            .toggle-wrapper {
                margin-bottom: 1.7rem;
            }
            .error-message-wrapper {
                min-height: 1.7rem;
                width: 12rem;
                .error-message {
                    color: #BC1212;
                    padding: $space-4 10px;
                    @include type-12-regular;
                }
            }
        }
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 20px;
        .button-wrapper {
            margin: 20px 10px;
            .button-row-button {
                width: 130px;
            }
            
        }
    }
}
