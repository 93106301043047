@import '../../../../../assets/styles/variables';

.order-overview  {

    .status-wrapper {
        display: flex;
        height: 10px;
        align-items: center; 
    }
    .status-text {
        line-height: 12px;
        padding: 0;
        margin: 0;
        align-self: baseline;

    }

    .filters .aldi-box .aldi-chipinput {
        min-width: calc( $distance-default * 20);
    }
}
