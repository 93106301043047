@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";

.collect-timetable-modal {
    
    .subtitle {
        margin-bottom: 24px;
    }
    
    .input-row{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: $space-4;
    }
    
    .input-wrapper{
        margin: 0 $space-2;
        flex: 2
    }
    
    .select {
        margin: 0 $space-2;
        flex: 3;
    }
}




