@import "../../../assets/styles/variables";
@import "../../../assets/styles/effects.mixin";
@import "../../../assets/styles/typography.mixin";

$tile-size: 11rem;
$border-width: 6px;

.tile {
    background: $color-background-panel;
    @include drop-shadow-50;
    @include type-12-regular;

    padding: $distance-default;

    height: $tile-size - 3 * $distance-default;
    width: calc($tile-size - 2 * $distance-default - $border-width);

    display: flex;
    flex-direction: column;

    &.double {
        width: calc(2 * $tile-size + $distance-half - 2 * $distance-default - $border-width);
    }

    .content {
        flex-grow: 1;
    }

    i {
        text-align: right;
        font-size: 2em;
    }

    border-left: $border-width solid transparent;
    margin-right: $distance-half;
    margin-bottom: $distance-half;

}