@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_effects.mixin.scss";

.aldi-tbody {
    td {
        border-bottom: 1px solid $color-border-2;

        .subtext {
            font-size: 12px !important;
            color: $color-secondary-60;
        }
    }

    tr {
        height: 70px;
    }

    tr:nth-child(odd) {
        background: #fff;
    }
    tr:nth-child(even) {
        background: rgba(63, 192, 240, 0.05);

        td {
            border-bottom: 1px solid $color-border-2;
        }
    }
}