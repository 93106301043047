@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/typography.mixin";

.radio {
    display: flex;
    align-items: center;
    justify-content: center;
}
.radio-label {
    display: flex;
    align-items: center;
    line-height: $space-16;
    margin-right: $space-32;
    cursor: pointer;

    .radio-value-label {
        margin-left: $space-12;

        .radio-value-label--small {
            @include type-12-regular;
        }
    }
}

.radio-label>span {
}

.radio-button {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    font-family: inherit;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    place-content: center;
    margin: 0;
    cursor: pointer;
}

.radio-button:checked {
    border: 2px solid #1396CB;
}

.radio-button::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: rgba(0, 0, 0, 0.6);
}

.radio-button:checked::before {
    transform: scale(1);
    background-color: #1396CB;
}