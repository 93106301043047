@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";

.delivery-timetable-modal {
    .subtitle {
        margin-bottom: 24px;
    }
    
    .input-row{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .input-wrapper{
        margin: 0
    }
    
    .button-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $space-4;
    }

    .add-subdivision {
        max-width: unset;
        color: $color-primary-60;
    }
    
    .table-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        // align-items: stretch;
        margin: 0;
    }
    
    .row-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
    
    .timeframe-tile {
        cursor: pointer;
        background-color: #92D050;
    }
    
    .slot-tile {
        cursor: pointer;
        background-color: $color-primary-50;
    }
    
    .name {
        margin: 0;
        font-weight: 600;
        text-transform: uppercase;
        @include type-14-regular;
    }
    
    .sub-line {
        margin: 0;
        font-weight: 600;
        @include type-12-regular;
    }
    
    .button-and-modal-tile {
        width: 100%;
        max-width: unset;
        border-radius: 0.25rem;
        margin: $space-12;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: $space-8; 
    }
    
    .modal-tile {
        height: $space-80;
        color: $color-primary-light;
        min-width: 120px;
    }
    
    .button-tile {
        height: $space-96;
        background-color: $color-primary-light;
        min-width: 126px;
    }
    
    .add-timeframe-button {
        border: 1px dashed #92D050;
        color: #92D050;
        opacity: 0.7;
    }
    
    .add-timeframe-button:hover {
        opacity: 0.4;
        color: #92D050;
    }
    
    .add-timeframe-button-text {
        margin: 0 auto;
        font-weight: 600;
        @include type-12-regular;
    }
    
    .add-slot-button {
        border: 1px dashed $color-primary-50;
        color: $color-primary-50;
        opacity: 0.5;
    }
    
    .add-slot-button:hover {
        opacity: 0.3;
    }
    
    .add-slot-button-text {
        margin: 0 auto;
        font-weight: 600;
        @include type-12-regular;
    }
}



