@import "../../../assets/styles/aldi_colors.scss";
@import "../../../assets/styles/_variables.scss";

.aldi-kwmc-select {
    display: flex;
    min-width: 150px;

    .MuiFormControl-root {
        .MuiFormLabel-root {
            &.Mui-focused {
                color: $aldi-outline-blue;
            }
        }
        .MuiOutlinedInput-root {
            border-bottom-width: 0px;

            &.Mui-focused {
                .MuiSelect-icon {
                    color: $aldi-outline-blue;
                }

                fieldset {
                    border-color: $aldi-outline-blue;
                }
            }

            fieldset {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &.resettable {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                .MuiOutlinedInput-notchedOutline {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}
