@import "../../../assets/styles/_variables.scss";


.aldi-pagination {
    &.MuiTablePagination-root {
        border-bottom: none !important;

        .MuiTablePagination-toolbar {
            display: flex;
            padding-top: 10px;

            .MuiTablePagination-selectLabel,
            .MuiTablePagination-displayedRows {
                display: none;
            }

            .MuiInputBase-colorPrimary {
                order: 1;
                margin-right: 0 !important;
                border-bottom: 1px solid $color-border-2;
            }
        }
    }
}
