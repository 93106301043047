@import "../../../../assets/styles/aldi_colors";
@import "../../../../assets/styles/_typography.mixin.scss";
@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_effects.mixin.scss";

.table {
    table-layout: fixed;
    .wrapper {
        justify-content: center;
    }
    .time-entry {
        font-weight: 600;
    }
}

.table-head-spacer {
    padding-bottom: $space-4;
}
.timeframe-table {
    position: relative;

    .timeframe-week-wrapper {
        position: absolute;
        width: 87.5%;
        top: 0;
        right: 0;
        margin-top: 56px;
        padding-top: $space-4;
        height: calc(100% - $space-32);
        margin-left: 10rem;
        // border: 2px solid black;
        display: flex;
    }

    .timeframe-day-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: $space-48;
        flex: 1;
        height: 100%;

        .timeframe-day-box {
            position: absolute;
            height: 100%;
            width: calc( 100% - 2 * $space-8 );
            margin: 0 $space-8;
        }
    }

    .timeframe-wrapper {
        position: absolute;
        width: calc(100% - $space-16);
        display: flex;
        flex-direction: column;
    }

    .timeframe {
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: white;
        opacity: 0.7;
        z-index: 1;
    }

    .buffer {
        width: 100%;
        height: calc(100% - 2 * $space-4);
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: white;
        opacity: 0.7;
        margin-top: $space-4;
        background-color: $color-primary-50;
    }

    .buffer-text {
        margin: 0;
        @include type-10-regular;
    }

    .timeframe-add-button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        max-width: 100%;
        border-radius: 0.5rem;
        border: 1px dashed $color-primary-50;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $color-primary-50;
        background-color: $color-primary-light;
        opacity: 0.5;
        padding: 0;
    }

    .timeframe-add-button, .timeframe {
        transition: opacity 100ms ease-in-out;
    }

    .timeframe-add-button:hover, .timeframe:hover {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        background-color: $aldi-blue;
    }

    .timeframe-add-button-text,
    .disabled-button-text {
        width: calc(100% - 4px);
        display: block;
        white-space: initial;
        font-weight: 600;
        margin: 0 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        @include type-12-regular;
    }

    .disabled-button-text-small {
        width: calc(100% - 4px);
        display: block;
        white-space: initial;
        font-weight: 600;
        margin: 0 2px;
        @include type-10-regular;
    }

    .no-access-icon {
        font-size: 32px;
        margin-top: $space-12;
    }

    .timeframe-name {
        margin: 0;
        font-weight: 600;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        @include type-14-regular;
    }

    .timeframe-time {
        margin: 0;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        @include type-12-regular;
    }

    .disabled-button {
        width: 100%;
        max-width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        background-color: $color-secondary-70;
        opacity: 0.5;
        padding: 0;
    }
}

.container-header-week-range-picker {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: $space-32;
    position: relative;

    .week-range-picker {
        position: absolute;
        z-index: 1000;
        @include drop-shadow-50;
        top: calc(100% + $space-2);
        right: 0;
    }
}

.header-row {
    width: 100%;
    display: flex;

    .placeholder {
        display: block;
        flex-grow: 1;
    }
}

.container-item {
    display: flex;
    width: auto;
    height: auto;
    line-height: 40px;
    color: black;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
}
