@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";

.subtitle {
    margin-bottom: 24px;
}

.select-wrapper {
    margin: $space-4;
    padding: $space-4;
    width: calc(100% - $space-4);
}

.radio-group {
    display: flex;
}

.radio-value-label {
    color: $color-primary-100;
    font-size: 1rem;
    font-weight: 400;
    margin-right: $space-8;
    line-height: normal;
}
