@import "../../../../assets/styles/_typography.mixin.scss";
@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_effects.mixin.scss";

.tour-overview {

    .checkbox-label {
        margin: 0;
        @include type-16-regular;
        display: flex;
        align-items: center;
        text-transform: none;
        margin-right: $space-40;
        font-weight: 500;
        line-height: $space-24;
        color: #79747E;
    }

    .add-tour-button {
        width: 380px;
    }

    .tour-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        @include full-width-tile;
        gap: $space-48;

        .tour-row {
            margin: 0;
            display: flex;
            align-items: center;
            position: relative;
            height: $space-40;
        }

        .tour-card {
            background: #FAFDFE;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            padding: $space-16;
            width: 168px;
            height: 196px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #79747E;
        }

        .tour-title-wrapper {
            display: flex;
            justify-content: center;
        }

        .tour-title {
            margin: 0;
            justify-content: center;
            align-items: center;
            @include type-16-regular;
            font-weight: 500;
            line-height: $space-24;
            display: flex;
            align-self: center;
            text-transform: uppercase;
        }

        .tour-text {
            position: absolute;
            margin-left: 50px;
            @include type-14-regular;
            font-weight: 400;
            display: flex;
            text-align:start;
            justify-content: flex-start;
        }

        .button {
            align-self:center;
            color: #79747E;
            height: $space-40;
            margin-bottom: 2px;
        }

        .button-title {
            display: flex;
            @include type-14-regular;
            font-weight: 500;
            line-height: 20px;
        }

        .icon {
            @include type-16-regular;
            margin-right: $space-24;
            margin-left: $space-8;
        }

        .icon-button {
            @include type-16-regular;
            margin-right: $space-8;
        }

        .icon-bold {
            font-weight: 700;
        }

        .tour-card-inactive {
            color: $color-secondary-50;
        }
    }
}

.container-item {
    display: flex;
    width: auto;
    height: auto;
    line-height: 40px;
    color: black;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
}


