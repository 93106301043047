@import "../../../assets/styles/variables";
@import "../../../assets/styles/effects.mixin";
@import "../../../assets/styles/typography.mixin";

$badge-size: 8rem;
$border-width: 1px;

.badge {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px $space-16;
    height: $space-40;
    border-radius: 20px;
    

    &.danger{
        background: $color-danger;
        color: #590505;
    }

    &.success{
        background: $color-success;
        color: #084119;
    }

    .badge-text {
        padding: 0;
        margin: 0;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 10px;
    }
    @-moz-document url-prefix() {
        .badge-text {
            padding-top: 0.1em;
        }
      }
}