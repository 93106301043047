@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/aldi_colors.scss";


.aldi-checkbox-wrapper {
    align-items: center;

    .MuiCheckbox-root.Mui-checked {
        color: $aldi-blue;
    }
}
