$color-background-default: #FCFDFF;
$color-border: #EAEFF3;
$color-border-2: #dbe1e5;
$color-border-sidebar: #979797;
$color-background-panel: #FFFFFF;
$color-background-modal: #FFFFFF;
$color-blue: #3fc0f0;

$color-success: #198038;
$color-danger: #ff6153;

$color-primary-light: #F7F9FB;
$color-primary-medium: #EAF0F4;

$color-primary-30: #79CFF1;
$color-primary-40: #56C0EB;
$color-primary-50: #2CAFE4;
$color-primary-60: #1396CB;
$color-primary-80: #0D5C7C;
$color-primary-70: #1079A4;
$color-primary-80: #0D5C7C;
$color-primary-100: #212428;

$color-secondary-0: #FFFFFF;
$color-secondary-10: #F8FAFB;
$color-secondary-20: #EDF2F5;
$color-secondary-30: #D5DEE7;
$color-secondary-40: #B6C2CD;
$color-secondary-50: #93A1AE;
$color-secondary-60: #748595;
$color-secondary-70: #596A7A;
$color-secondary-80: #303F4E;
$color-secondary-90: #202D39;
$color-secondary-100: #18222C;
$color-secondary-110: #0E161D;
$color-secondary-120: #000000;
$color-secondary-130: #000000;

$distance-default: 1rem;
$distance-half: $distance-default / 2;
$distance-double: $distance-default * 2;

$space-2: 0.125rem;
$space-4: 0.25rem;
$space-8: 0.5rem;
$space-12: 0.75rem;
$space-16: 1rem;
$space-24: 1.5rem;
$space-32: 2rem;
$space-40: 2.5rem;
$space-48: 3rem;
$space-64: 4rem;
$space-80: 5rem;
$space-96: 6rem;
$space-160: 10rem;