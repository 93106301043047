@mixin drop-shadow-50 {
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));
}

@mixin drop-shadow-25 {
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.05));
}

@mixin drop-shadow-30 {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}