@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.aldi-kwmc-textinput {
    position: relative;
    @include type-12-regular;

    .MuiInputBase-root {
        border-bottom-width: 0;

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #3fc0f0;
        }
    }

    .search-icon {
        font-size: 18px;
        color: #49454F;
        margin-right: $space-8;
    }

    .reset-button {
        padding: 0;
        color: #79747E;
    }

    .kwmc-textinput-helper-text {
        color: initial;
        margin-top: $space-4;
    }

    &:hover {
        .kwmc-reset-button {
            visibility: visible;
        }
    }

    &.kwmc-error-type {
        .MuiInput-root .MuiInput-input {
            border: 2px solid $color-danger;
        }

        .kwmc-textinput-helper-text {
            color: $color-danger;
        }
    }
}
