@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_typography.mixin.scss";

.kw-calendar-month {
    .kw-month-header {
        display: flex;
        background-color: $color-secondary-20;
        border-bottom: 1px solid $color-secondary-30;
        .kw-month-show-previous, .kw-month-show-next {
            cursor: pointer;
            margin: auto $space-8;
            &.hidden {
                visibility: hidden;
            }
        }
        .kw-month-title {
            height: $space-40;
            display: flex;
            flex: auto;
            justify-content: center;
            align-items: center;
            background: $color-secondary-20;
            box-sizing: border-box;
            @include type-12-regular;
        }
    }
}

.kw-month-view {
    background-color: $color-secondary-20;
    width: 8 * $space-40;
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    @include type-12-regular;



    .kw-week {
        display: flex;

        &.kw-week-headers {
            font-weight: 400;
        }

        &.kw-date-item-calendar-week {
                background-color: $color-secondary-60;
            }

        &.kw-week-current {
            background-color: $color-secondary-30;
        }

        &:hover {
            background-color: $color-secondary-40;
        }

        &.kw-week-selection-start, &.kw-week-selection-end {
            background-color: $color-secondary-50;
            .kw-date-item-calendar-week {
                background-color: $color-primary-60;
            }
        }

        &.kw-week-selection-start {
            border-top-left-radius: $space-4;
            .kw-date-item-calendar-week {
                border-top-left-radius: inherit;
            }
        }

        &.kw-week-selection-end {
            border-bottom-left-radius: $space-4;

            .kw-date-item-calendar-week {
                border-bottom-left-radius: inherit;
            }
        }

        &.kw-week-selection-between {
            background: $color-secondary-30;
            .kw-date-item-calendar-week {
                background-color: $color-primary-50;
            }
        }
    }

    .kw-date-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: $space-40;
        height: $space-40;
        cursor: pointer;

        &.kw-date-item-calendar-week {
            background-color: $color-secondary-30;
        }

        &.kw-date-item-not-current {
            color: $color-secondary-50
        }

        &.kw-date-item-selected {
            background: $color-primary-60;
        }
    }
}
