@import "../../../../assets/styles/_typography.mixin.scss";
@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_effects.mixin.scss";


.add-cleveron-button {
    width: 380px;
    
    .plus-icon {
        margin-right: 8px;
    }
}



.cleverons-overview {
    .general-table-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include full-width-tile;

        .badge {
            width: $space-64;
        }

        .subtext {
            @include type-10-regular;
        }

        .address-cell {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: $space-96;
            max-width: $space-96;
        }

        .last-cell {
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: end;
            min-width: 105px;

            .menu {
                margin-left: 8px;
            }
        }

        .edit-button {
            width: $space-40;
            height: $space-40;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
        }
        .edit-button:hover {
            background: #fff;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.17);
        }
    }
}

.my-thumb {

    background-color: yellow;


}

.box {
    background: $color-background-panel;

    &.chart {
        canvas {
            margin: $distance-default;
        }
    }

    &.filter {
        width: fit-content;
        margin-left: $distance-default;
        position: relative;
        z-index: 1;

        &:first-of-type {
            margin-left: 0;
        }
    }
}

.cell-group-start {
    text-align: left;
}

.filters {
    display: flex;
    margin-bottom: $space-32;

    .placeholder {
        display: block;
        flex-grow: 1;
    }
}

.action-icons {
    display: flex;
    flex-direction: row;
}

.cleveron-modal-wrapper {
    .modal-wrapper {
        .content-wrapper { 
            margin: auto;
        }
    }
}
