@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";
@import "../../../../../assets/styles/_forms.scss";

.subtitle {
    margin-bottom: 24px;
}

.create-cleveron-input-row {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    gap: $space-16;
    margin: $space-16 0;
}

.small-input-wrapper {
    width: 23%;
    margin: 0;
}

.create-cleveron-input-wrapper {
    justify-content: space-between;
    align-items: center;
    margin: 0;
    flex: 2 1 0;
}

.input-label {
    color: $color-secondary-120;
    padding-left: 0;
    width: 28%;
    font-weight: 300;
    @include type-14-regular;
    height: $space-40;
    margin: 0;
    display: flex;
    align-items: center;
}

.create-cleveron-select-wrapper {
    margin: $space-4 0;
    height: $space-40;
    width: 100%;
}

.create-cleveron-select {
    width: 100%;
    font-size: 14px !important;
    color: #79747E !important;
}

.create-cleveron-button-row {
    display: flex;
    justify-content: center;
    margin-top: $space-16;
}

.add-group-button {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3FC0F0;
    display: flex;
    align-items: center;
    margin-right: $space-96;
}

.plus-icon {
    font-size: 18px;
    margin-right: $space-4;
}

.toggle-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.toggle-label {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    padding-left: $space-8;
}
