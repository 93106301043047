@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";

.slot-modal {
    .aldi-modal-background {
        .modal-wrapper {
            width: fit-content;
        }
    }
}
