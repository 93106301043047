@mixin roboto {
    font-family: 'Roboto', sans-serif;
}

@mixin open-sans {
    font-family: 'Open Sans', sans-serif;
}

@mixin base-typography {
    font-weight: 300;
}

@mixin type-semi-bold {
    font-weight: 400;
}

@mixin type-bold {
    font-weight: 500;
}

@mixin type-8-regular {
    font-size: 0.5rem;
}

@mixin type-10-regular {
    font-size: 0.625rem;
}

@mixin type-12-regular {
    font-size: 0.75rem;
}

@mixin type-14-regular {
    font-size: 0.875rem;
}

@mixin type-16-regular {
    font-size: 1rem;
}

@mixin type-20-regular {
    font-size: 1.25rem;
}

@mixin type-24-regular {
    font-size: 1.5rem;
}

@mixin headline-1 {
    @include base-typography;
    @include type-16-regular;
}

@mixin headline-2 {
    @include type-bold;
    @include type-12-regular;
}