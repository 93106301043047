@import './_typography.mixin.scss';
@import './_effects.mixin.scss';
@import './_variables.scss';
@import './_tiles.mixin.scss';

@mixin bold-cell {
    font-weight: 500;
}

@mixin secondary-header-cell {
    height: $space-16;
    padding: 0 $space-16 $space-12 $space-16;
    box-sizing: border-box;
    text-transform: capitalize;
    color: $color-secondary-60;
    @include type-10-regular;
    font-weight: 200;
}

@mixin primary-header-cell {
    vertical-align: top;
    box-sizing: border-box;
    padding: $space-32 $space-16 $space-8 $space-16;
    text-transform: capitalize;
    line-height: 1.2;

    .headline-subtext {
        @include type-10-regular;
        color: $color-secondary-60;
        font-weight: 200;
    }
}

.single-row-thead {
    height: 38px;

    td {
        height: $space-32;
    }
}

@mixin table-foot {
    tr:first-of-type {
        td { border-top: 1px solid $color-primary-60; }
    }
}

@mixin table-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include full-width-tile;
    @include drop-shadow-50;

    tbody {
        tr:last-of-type td {
            border: 0;
        }
    }

    tfoot {
        @include table-foot;
    }
}

.general-table-wrapper {
    @include table-wrapper;
}

.aldi-general-table-wrapper {
    @include table-wrapper;
    filter: unset;
}

.centered-entries {
    td {
        text-align: center;
    }
}

tr.secondary-header-row {
    td {
        @include secondary-header-cell;
    }
}

table {
    .title-column-cell {
        font-weight: 500;
    }

    .status {
        vertical-align: middle;

        .wrapper {
            height: 100%;
            display: inline-flex;
            align-items: center;
        }
    }

    td.utility-cell {
        button {
            float: right;
        }
    }
}

@mixin aldi-table-foot {
    tr:first-of-type {
        td { border-top: 1px solid #dbe1e5; }
    }
}

@mixin aldi-table-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include full-width-tile;

    tbody {
        tr:last-of-type td {
            border: 0;
        }
    }

    tfoot {
        @include table-foot;
    }
}
