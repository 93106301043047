@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_typography.mixin.scss";
@import "../../../../../assets/styles/_forms.scss";

.location-modal {

    .subtitle {
        margin-bottom: 24px;
    }

    .heading {
        margin-bottom: 12px !important;
    }

    .location-data-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: $space-8 0;
        border-bottom: 1px solid #D9D9D9;
        margin-top: 0;
        margin-bottom: 12px;
    }

    .badge-wrapper {
        @include type-12-regular;
        flex: 1;
    }

    .location-data-wrapper {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin: 0;
        flex: 1;

        .value {
            @include type-16-regular;
            font-weight: 400;
        }

        .label {
            @include type-12-regular;
            margin-left: $space-8;
            color: #979797;
            font-weight: 400;
        }
    }

    .area-wrapper {
        flex: unset;
        margin-right: $space-24;
    }

    .status-wrapper {
        justify-content: flex-start;
        gap: $space-16;

        .value {
            min-width: 4rem;
        }

        .label {
            min-width: 16rem;
        }
    }

    .location-article-segment {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: $space-8 0;
        border-bottom: 1px solid #D9D9D9;
        margin-top: 0;
        margin-bottom: 12px;
    }

    .search-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .article-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .article-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid black;
        border-radius: $space-4;
        margin-bottom: $space-16;
    }

    .article-name {
        @include type-14-regular;
        margin: 0 0 0 $space-16;
    }

    .article-remove-button {
        padding: 0 $space-8;
        height: $space-32;
        padding-top: $space-2;
    }

    .remove-icon {
        font-size: 18px;
    }

    .search-input-wrapper {
        margin-bottom: $space-16;
        margin-top: $space-8;
        margin-right: $space-16;
    }

    .input-wrapper {
        width: 20rem;
    }

    .button-wrapper {
        margin-bottom: $space-16;
        margin-top: $space-8;

        button { height: 41px; }
    }

    .input-label {
        color: $color-secondary-120;
        padding-left: 0;
        width: 28%;
        font-weight: 300;
        @include type-14-regular;
        height: $space-40;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .toggle-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .toggle-label {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        padding-left: $space-8;
    }
}
