@mixin tile {
    margin-bottom: $distance-default;
}

@mixin single-tile {
    @include tile;
    width: calc(25% - 3/4 * $distance-default);
}

@mixin double-tile {
    @include tile;
    width: calc(2 * (25% - 3/4 * $distance-default) + $distance-default);
}

@mixin triple-tile {
    @include tile;
    width: calc(3 * (25% - 3/4 * $distance-default) + 2 * $distance-default);
}

@mixin full-width-tile {
    @include tile;
    width: 100%;
}