@import "../../../assets/styles/aldi_colors";
@import "../../../assets/styles/variables";

.aldi-kwmc-multi-select {
    display: flex;

    .value-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .placeholder-text {
        margin: 0;
    }

    .number-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #fff;
        font-weight: 600;
        background-color: $color-blue;
        border-radius: 9px;
        width: 16px;
        height: 16px;
        margin-left: 4px;
    }

    .MuiFormControl-root {
        .MuiFormLabel-root {
            &.Mui-focused {
                color: $aldi-outline-blue;
            }
        }
        .MuiOutlinedInput-root {
            border-bottom-width: 0px;

            &.Mui-focused {
                .MuiSelect-icon {
                    color: $aldi-outline-blue;
                }

                fieldset {
                    border-color: $aldi-outline-blue;
                }
            }

            fieldset {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &.resettable {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                .MuiOutlinedInput-notchedOutline {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}
