.main-table {
    @include table-wrapper;
}

.filters {
    display: flex;
    margin-bottom: $space-32;

    .placeholder {
        display: block;
        flex-grow: 1;
    }

    .medium-variable-gap {
        max-width: $space-40;
        min-width: $space-24;
        flex: 1 1;
    }

    .small-fixed-gap {
        max-width: $space-24;
        min-width: $space-24;
        flex: 1 1;
    }
}

.container-header {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: $space-32;

    .placeholder {
        display: block;
        flex-grow: 1;
    }
}

.header-title {
    @include roboto;
    font-weight: 300;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $color-blue;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    height: $space-40;
}

.box {
    background: $color-background-panel;
    @include drop-shadow-25;

    &.chart {
        canvas {
            margin: $distance-default;
        }
    }

    &.filter {
        width: fit-content;
        margin-left: $distance-default;
        position: relative;
        z-index: 1;

        &:first-of-type {
            margin-left: 0;
        }
    }
}

.aldi-box {

    &.chart {
        canvas {
            margin: $distance-default;
        }
    }

    &.filter {
        width: fit-content;
        margin-left: 0;
        position: relative;
        z-index: 1;

        &:first-of-type {
            margin-left: 0;
        }
    }
}
