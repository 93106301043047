@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_table.mixin.scss";

.cluster-overview {
    table {
        .table-row-complete {
            color: $color-success;
        }

        td {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: $space-64;

            &.title-column-cell {
                min-width: 120px;
            }
        }

        td.status {
            max-width: $space-96;
        }

        .menu {
            width: $space-40;
        }

        .progress-cell {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: $space-48;
            max-width: $space-48;
        }
    }
}
