@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";
@import "../../../assets/styles/aldi_colors.scss";

$space-6: $space-4 + $space-2;
$input-bg-color: rgba(0,0,0,0);

.aldi-chipinput.kwchipinput {
    position: relative;
    display: flex;
    padding-left: $space-8;
    height: calc($space-40 - 2px);
    width: calc($space-64 * 6);
    @include type-12-regular;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.23);
    background-color: $input-bg-color;

    &:hover {
        border: 1px solid black;
    }

    &.focus {
        border: 1px solid $aldi-blue;

        .kwchipinput-outline {
            position: absolute;
            z-index: -1;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 2px solid $aldi-blue;
            border-radius: 4px;
            left: calc(1px - $space-2);
            top: -1px;
        }
    }

    .kwchipinput-input {
        box-sizing: border-box;
        height: calc($space-40 - 2px);
        border: none;
        width: 100%;
        min-width: $space-160;
        border-bottom: 2px solid $color-border;
        padding: 0 0 0 $space-4;
        border: none;
        outline: none;
        background-color: rgba(0,0,0,0);
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        color: rgba(0,0,0,0.87);

        &::placeholder {
            color: rgba(0,0,0,0.4);
        }

        &.no-focus {
            caret-color: transparent;
        }
    }

    .kwchipinput-chip-wrapper {
        max-width: calc( 3 * $space-64);
        display: flex;
        overflow: hidden;

        .kwchipinput-chip {
            @include type-12-regular;
            box-sizing: border-box;
            width: auto;
            border-bottom: 2px solid $color-border-2;
            padding: $space-2 $space-8;
            color: $color-secondary-10;
            white-space: nowrap; 
            border-radius: $space-4;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin: $space-6 $space-8 $space-6 0;
            background-color: $aldi-blue;

            &.focus {
                background-color: $color-danger;
            }

            .kwchipinput-chip-content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-weight: 400;
                font-size: $space-16;
                .kwchipinput-chip-label {
                    display: flex;
                }
            }
            .kwchipinput-chip-close {
                display: flex;
                padding-left: $space-2;
                cursor: pointer;
                background-color: rgba(0,0,0,0);
                color: rgba(255,255,255,0.5);
                margin-top: 1px;

                &:hover {
                    color: rgba(255,255,255,0.9);
                }
            }
        }
    }

    .kwchipinput-reset {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc($space-40 - 2px);
        padding: 0 $space-16;
        font-size: $space-16;
        width: $space-32;
        color: $color-secondary-60;
        box-sizing: border-box;
        cursor: pointer;
        border: none;
        border-radius: 0 3px 3px 0;
        margin-right: $space-8;

        &:hover {
            background-color: inherit;
            color: $color-secondary-80;
        }
    }
}
