@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.sidebar {
    display: flex;
    background-color: $color-background-default;

    @include roboto;
    @include type-12-regular;

    flex-direction: column;

    .logo-box {
        flex-grow: 0;
        padding: 30px 70px;
        margin: 0 10px;
        display: flex;
        align-items: center;
        gap: $space-12;
        cursor: pointer;
        border-bottom: 1px solid $color-border-sidebar;

        .logo {
            width: 100%;
            height: 100%;
        }

        .text {
            @include type-10-regular;

            .product-name {
                @include type-12-regular;
                font-weight: 600;
            }
        }
    }

    .sidebar-group {
        @include type-12-regular;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        border-bottom: 1px solid $color-border-sidebar;
        padding: $space-16 0;
        padding-left: 20px;

        .sidebar-group-header {
            display: flex;
            align-items: center;

            @include type-16-regular;
            font-weight: 700;
            .icon {
                margin-right: 12px;
                width: 18px;
            }

        }

        .sidebar-item {
            font-weight: 400;
            margin: 4px 0;
            margin-left: 16px;
            padding: 0 0 0 10px;
            height: $space-40;
            display: inline-flex;
            align-items: center;
            box-sizing: border-box;
            border-left: $space-4 solid transparent;
            @include type-14-regular;

            &:first-of-type {
                margin-top: $space-16;
            }

            cursor: pointer;

            &:hover {
                background-color: $color-secondary-20;
                border-color: $color-secondary-30;
            }
        }

        .selected-item {
            background-color: rgba(63, 192, 240, 0.15);
            border-color: $color-blue;

            &:hover {
                background-color: rgba(63, 192, 240, 0.15);
                border-color: $color-blue;
            }
        }

        &.configuration {
            display: none;
        }
    }

}