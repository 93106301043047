@import "../../../assets/styles/_typography.mixin.scss";
@import "../../../assets/styles/_variables.scss";

$tile-size: 12rem;
$border-width: 6px;


.dashboard {
    display: flex;
    flex-wrap: wrap;
    gap: 3em 4em;
    margin-top: 0.5rem;

    .tiles {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: ($tile-size) * 3;

        flex-direction: column;

        .tiles-body {
            display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
        }

        h1 {
            margin-bottom: $space-24;
        }

        .tile {
            cursor: pointer;
        }

        &.ff .tile {
            --accent-color: #58616D;
            border-color: var(--accent-color);
            .icon {
                color: var(--accent-color);
            }
        }

        &.click-and-collect .tile {
            --accent-color: #69467A;
            border-color: var(--accent-color);
            .icon {
                color: var(--accent-color);
            }
        }

        &.home-delivery .tile {
            --accent-color: #467a76;
            border-color: var(--accent-color);

            .icon {
                color: var(--accent-color);
            }
        }

        &.storage .tile {
            --accent-color: #394C7E;
            border-color: var(--accent-color);
            .icon {
                color: var(--accent-color);
            }
        }

        &.configuration .tile {
            --accent-color: $color-secondary-100;
            border-color: var(--accent-color);

            .icon {
                color: var(--accent-color);
            }
        }

        .tile {
            &:hover {
                --accent-color: #000;
            }
        }

    }
}
