@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_table.mixin.scss";
@import "../../../../assets/styles/_typography.mixin.scss";

.aldi-thead {
    font-weight: 500;
    color: $color-secondary-0;

    td, th {
        background-color: $color-blue;
        vertical-align: top;
        box-sizing: border-box;
        padding: $space-8 $space-16 $space-8 $space-16;
        line-height: 22px;
        @include type-14-regular;
        font-weight: 600;

        .headline-subtext {
            @include type-12-regular;
            line-height: 19px;
            color: $color-secondary-0;
            font-weight: 400;
        }
    }

    position: relative;

    .loader,
    &::before {
        position: absolute;
        content: ' ';
        background-color: $color-secondary-0;
    }

    &::before {
        right: 0;
        height: 1px;
        bottom: 0;
        left: 0;
    }

    .loader {
        display: block;
        width: 20px;
        height: 3px;
        bottom: 2px;
        left: 0;
        animation: loading 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    }

    @keyframes loading {
        0%,
        100% {
            left: 0;
        }
        50% {
            left: calc(100% - 20px);
        }
    }
}
