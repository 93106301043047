@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/_effects.mixin.scss";
@import "../../../assets/styles/_typography.mixin.scss";

.aldi-general-modal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.6);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include type-12-regular;
    z-index: 100;

    .general-modal-wrapper {
        background: $color-background-modal;
        width: 348px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
        border-radius: $space-4;
        padding: $space-16;
        max-height: 95%;
        color: $color-secondary-130;
        overflow-y: auto;
        line-height: 1.4;
        font-size: $space-16;
        z-index: 1;

        .title {
            margin: 0;
            line-height: $space-32;
            font-weight: 500;
            color: #000;
        }
        .subtitle {
            margin: 0;
            color: #49454F;
            font-weight: 400;
            line-height: 20px;
            @include type-12-regular;
        }
        .heading {
            @include headline-2;
            color: #79747E;
            font-weight: 500;
            margin-top: $space-24;
            margin-bottom: $space-16;
        }
        .button-row {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: 20px;
            .button-wrapper {
                margin: 0 0 0 1rem;
            }
        }
        .content-wrapper {
            margin: $space-8;
            padding-bottom: $space-24;
        }
        .validation {
            color: $color-danger;
        }
    }

}
