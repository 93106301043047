@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_typography.mixin.scss";

.location {
    font-weight: 500;
    display: flex;
    align-items: center;

    .icon {
        font-size: $space-16;
        margin-right: $space-4;
    }
}