@import "../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../assets/styles/_variables.scss";
@import "../../../../assets/styles/_effects.mixin.scss";
@import "../../../../assets/styles/_table.mixin.scss";

.activity-fulfilment-overview {

    .activity-fulfilment {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .tabbed-content {
            width: calc(60% - 8px);
            max-width: 580px;
            margin-bottom: $space-48;
        }

        .aldi-box {
            background: $color-background-panel;

            .box-header {
                padding: $distance-default;
            }

            @include single-tile;

            &.chart {
                canvas {
                    margin: $distance-default;
                }
            }

            &.status-ambient {
                @include triple-tile;
            }

            &.status-chilled {
                @include triple-tile;
            }

            &.status-frozen {
                @include triple-tile;
            }

            &.status-click-and-collect-ambient-chilled {
                @include triple-tile;
            }

            &.status-click-and-collect-frozen {
                @include triple-tile;
            }

        }

        .tabpanel {
            display: flex;
            flex-direction: column;
            gap: $space-24;
            .graph-wrapper {
                border: 1px solid $color-border-2;
            }
        }

    }
}
