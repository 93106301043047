@import "../../../../../assets/styles/_tiles.mixin.scss";
@import "../../../../../assets/styles/_variables.scss";
@import "../../../../../assets/styles/_effects.mixin.scss";
@import "../../../../../assets/styles/_table.mixin.scss";

.activities-data-table {
    .single-row-thead {
        white-space: nowrap;
    }
}
